

  import { Options, Vue } from 'vue-class-component';
  
  @Options({
    name: 'Textbox',
    props: {
      textValue: {
        type: String,
      },
      placeholderValue: {
        type: String,
      },
      onEnter: {
        type: Function,
        required: false,
      },
    },
    methods: {
      handleInput(event: any) {
        this.$emit("update:textValue", event.target.value)
      }
      
    }
  })

  export default class Textbox extends Vue {}
