
  import { defineComponent } from 'vue';
  
  export default defineComponent({
    name: 'Button',
    props: {
      text: {
        type: String,
        default: 'Button',
      },
      color: {
        type: String,
        default: 'green',
      },
      additionalClass: {
        type: String,
        default: '',
      },
      onClick: {
        type: Function,
        required: true,
      }
    }
  })
