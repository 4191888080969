
  import { defineComponent } from 'vue';
  import Button from '@/components/input/Button.vue';

  export default defineComponent({
    name: 'Header',
    props: {
      title: {
        type: String,
        default: 'Know Your Friends!'
      }
    },
    components: {
      Button,
    }
  })
