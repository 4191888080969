import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "splash" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_Button, {
      onClick: _ctx.handleJoin,
      additionalClass: "background-join",
      text: "Join existing game"
    }, null, 8, ["onClick"]),
    _createVNode(_component_Button, {
      onClick: _ctx.handleNew,
      text: "Start new game"
    }, null, 8, ["onClick"])
  ]))
}