import { withKeys as _withKeys, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-94dd37c0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "input-wrapper" }
const _hoisted_2 = ["value", "placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      type: "text",
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleInput && _ctx.handleInput(...args))),
      onKeyup: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.onEnter && _ctx.onEnter(...args)), ["enter"])),
      value: _ctx.textValue,
      placeholder: _ctx.placeholderValue
    }, null, 40, _hoisted_2)
  ]))
}