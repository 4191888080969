
  import { defineComponent } from 'vue'
  import Button from '@/components/input/Button.vue';
  
  export default defineComponent({
    name: 'Splash',
    props: {},
    components: {
      Button,
    },
    methods: {
      handleJoin() {
        this.$router.push({ name: "JoinBlank" })
      },
      handleNew() {
        this.$router.push({ name: "CreateGame" })
      }
    }
  })
